function appendButton() {
  const z = document.createElement("div"); // is a node
  const node = document.getElementsByClassName("mat-tab-header")[0];
  if (node !== null && node !== undefined) {
    z.innerHTML =
      '<a id="buttonTest" class="pointer dashboardAddButton"><img src="assets/images/add_circle-24px.svg"></a>';
     if(!document.getElementById('buttonTest')) {
       node.appendChild(z);
     }
    
  }
}

